


















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import Alert from '@/store/entities/catalogs/alert';

@Component({
    components: { ModalView, FormBase },
    watch: {
        value: function (val) {
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.alert = Util.extend(true, {}, this.$store.state.alert.editModel);
            }
        },
        modalShow: function (val) {
            if (!val) {
                this.$emit('input', false);
            } else {
                (this as any).initForm();
            }
        }
    }
})

export default class AlertForm extends ListBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    alert: Alert = new Alert();
    modalShow: boolean = false;
    formBase: boolean = true;
    image: any = null;
    modalSetting: any = {
        entity: "alert",
        title: 'Alerta',
        width: "600px",
        showForm: false,
    };
    tags: Array<any> = [
        '{cliente}',
        '{solicitante}',
        '{fecha}',
        '{nro_solicitud}'
    ];
     
    items = [
        this.gItem({ type: 'text', prop: 'name', label: 'Nombre', size: 8, required: true }),
        this.gItem({ type: 'active', size: 4 }),
        this.gItem({ type: 'object', prop: 'frecuency_id', generalType: 'T_FRECUENCY', label: 'Frecuencia', size: 6, required: true }),
        this.gItem({ type: 'object', prop: 'send_type_id', generalType: 'T_ALERT', label: 'Destino de Alerta', size: 6, required: true }),
        this.gItem({ type: 'text', prop: 'subject', label: 'Asunto', size: 12, required: true }),
        this.gItem({ type: 'textarea', prop: 'content', label: 'Contenido', size: 12, rows: 6, required: true }),            
    ]


    initForm() {   
    }

    async save() {
        if (this.$refs.entityForm != undefined && (this.$refs.entityForm as any).validate()) {
            let newModel = await this.$store.dispatch({
                type: 'alert/' + (this.alert.id > 0 ? 'update' : 'create'),
                data: this.alert
            });
            (this.$refs.entityForm as any).reset();
            this.$emit('save-success', newModel);
            this.$emit('input', false);
            Util.abp.notify.success(this, 'Guardado Correctamente');
        }
    }

    cancel() {
        this.$emit('input', false);
    }

    mounted() {
    }

    created() {
    }
}
