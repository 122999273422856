




















import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import AlertForm from './alert-form.vue';
import AlertOptions from './alert-options.vue';

class PageAlertRequest extends PageRequest {
}

@Component({
    components: { ListView, AlertForm, FormBase, AlertOptions }
})
export default class Alerts extends ListBase {
    entity: string = 'alert';
    pagerequest: PageAlertRequest = new PageAlertRequest();

    columns = [
        this.gCol('name', 'Nombre', 'text'),
        this.gColO({ type: 'text', value: 'frecuency_id', text: 'Frecuencia', generalType: true }),
        this.gColO({ type: 'text', value: 'send_type_id', text: 'Destino de Alerta', generalType: true }),
        this.gCol('active', 'Habilitado', 'text'),
        this.defaultActions()
    ]
    

    edit() {
        this.modalShow = !this.modalShow;
    }
    create() {
        this.modalShow = !this.modalShow;
    }
    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }     

    mounted() {
        
    }

    created() {
        
    }
}